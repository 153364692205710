import { Box, Center, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import Container from '../components/container'
import Layout from '../components/layout'

export default () => (
	<Layout>
		<Box pt={64} pb={32}>
			<Container>
				<Center>
					<Heading textAlign="center">
						<Text color="gray.500">404</Text> Página não econtrada.
					</Heading>
				</Center>
			</Container>
		</Box>
	</Layout>
)